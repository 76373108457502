import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { AnimatedSwitch, AnimatedRoute } from "react-router-transition";
import ImageGallery from "react-image-gallery";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            start: false,
            imgGallery: [],
            videoGallery: [],
            categories: {}
        };
    }

    renderVideo(video) {
        return (
            <div className="image-gallery-image">
                {video.embedUrl ? (
                    <div className="video-wrapper">
                        <a className="close-video"></a>
                        <iframe width="560" height="315" src={video.embedUrl} frameBorder="0" allowFullScreen></iframe>
                    </div>
                ) : (
                    <a>
                        <div className="play-button"></div>
                        <img src={video.original} />
                        {video.description && (
                            <span className="image-gallery-description" style={{ right: "0", left: "initial" }}>
                                {video.description}
                            </span>
                        )}
                    </a>
                )}
            </div>
        );
    }

    getCache = async url => {
        if (url.indexOf("http") > -1 && url.indexOf(".mp4") == -1 && url.indexOf(".mov") == -1) {
            console.log("getCache", url);
            const response = await fetch(url);
            const body = await response.data;
            return body;
        }
        return false;
    };

    getPassword = () => {
        const pass = prompt("Password");
        if (pass !== "Skystar123") this.getPassword();
        else return true;
    };

    componentDidMount() {
        this.getPassword();
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let token = params.get("token");
        token = token ? token : "default";
        console.log("token", token);

        fetch(process.env.REACT_APP_API_URL + "/data/" + token)
            .then(response => response.json())
            .then(data => {
                console.log("ddd", data);
                let temp = {};
                let imgGallery = [];
                let videoGallery = [];
                data.map(item => {
                    let rightSlider = [];
                    let leftSlider = [];

                    if (!temp.hasOwnProperty(item.parent_category)) {
                        temp[item.parent_category] = {
                            title: item.parent_category,
                            pages: {}
                        };
                    }
                    item.title = item.name;
                    item.content = item.text;

                    item["Items"].map(sliderItem => {
                        if (sliderItem.position === "right") rightSlider.push(sliderItem);
                        else leftSlider.push(sliderItem);

                        if (sliderItem.video != "") item.video = sliderItem.video;

                        if (sliderItem.categoryId == 31) {
                            imgGallery.push({
                                original: sliderItem.image,
                                thumbnail: sliderItem.image
                            });
                        }
                        if (sliderItem.categoryId == 32) {
                            console.log("sliderItem", sliderItem);
                            let itemVideo = {
                                original: sliderItem.image,
                                thumbnail: sliderItem.image
                            };
                            if (sliderItem.video) {
                                itemVideo.embedUrl = sliderItem.video;
                                itemVideo.renderItem = () => this.renderVideo(itemVideo);
                            }
                            videoGallery.push(itemVideo);
                        }

                        this.getCache(sliderItem.video || sliderItem.image);
                    });

                    item.leftSlider = leftSlider;
                    item.rightSlider = rightSlider;

                    temp[item.parent_category]["pages"][item.slug] = item;
                });

                this.setState({ imgGallery: imgGallery, videoGallery: videoGallery });

                return temp;
            })
            .then(temp =>
                this.setState({ categories: temp }, () => {
                    setTimeout(
                        function() {
                            this.setState({ loading: false });
                        }.bind(this),
                        2000
                    );
                })
            );
    }

    startNow(e) {
        this.setState({ start: true });
    }

    render() {
        const { categories, loading, start } = this.state;

        let firstCategory = Object.keys(categories)[0];
        let firstCategoryObject = categories[firstCategory];
        let firstPage = false;
        if (firstCategoryObject) {
            firstPage = Object.keys(firstCategoryObject.pages)[0];
            console.log(firstPage);
        }

        return !firstPage || loading || !start ? (
            <div id="loading">
                {!loading ? (
                    <a id="start" onClick={e => this.startNow(e)}>
                        START
                    </a>
                ) : null}
            </div>
        ) : (
            <Router>
                <AnimatedSwitch
                    atEnter={{ opacity: 0 }}
                    atLeave={{ opacity: 0 }}
                    atActive={{ opacity: 1 }}
                    className="switch-wrapper"
                >
                    <Route
                        exact
                        path="/"
                        render={props => (
                            <Home
                                categories={this.state.categories}
                                category={firstCategory}
                                page={firstPage}
                                imgGallery={this.state.imgGallery}
                                videoGallery={this.state.videoGallery}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/:category/:page"
                        render={props => (
                            <Home
                                categories={this.state.categories}
                                category={props.match.params.category}
                                page={props.match.params.page}
                                imgGallery={this.state.imgGallery}
                                videoGallery={this.state.videoGallery}
                            />
                        )}
                    />
                </AnimatedSwitch>
            </Router>
        );
    }
}

class Video extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.state = {
            video_src: false,
            play: false
        };
    }

    exitHandler = () => {
        if (
            !document.fullscreenElement &&
            !document.webkitIsFullScreen &&
            !document.mozFullScreen &&
            !document.msFullscreenElement
        ) {
            ///fire your event
            this.setState({
                play: false
            });
        }
    };

    componentDidMount() {
        document.addEventListener("fullscreenchange", this.exitHandler);
        document.addEventListener("webkitfullscreenchange", this.exitHandler);
        document.addEventListener("mozfullscreenchange", this.exitHandler);
        document.addEventListener("MSFullscreenChange", this.exitHandler);

        this.setState({ video_src: this.props.src });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.src !== this.props.src) {
            this.setState({
                video_src: this.props.src,
                play: false
            });
        }
    }

    showVideo = e => {
        this.setState({ play: true }, () => {
            const elem = this.myRef.current;
            console.log("dd", elem);
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                /* Firefox */
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                /* IE/Edge */
                elem.msRequestFullscreen();
            }
        });
    };

    render() {
        let { play, video_src } = this.state;

        return (
            <div className="video-container">
                {!play && <a className="playbutton" onClick={e => this.showVideo(e)}></a>}
                {play && video_src && (
                    <div id="video-fullscreen">
                        <video width="320" height="240" controls autoPlay ref={this.myRef}>
                            <source src={video_src} type="video/mp4" />
                        </video>
                    </div>
                )}
            </div>
        );
    }
}

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            videoGallery: this.props.videoGallery,
            imgGallery: this.props.imgGallery,
            categories: this.props.categories,
            category: this.props.category,
            currentCategory: this.props.categories[this.props.category],
            page: this.props.page,
            currentPage: this.props.categories[this.props.category].pages[this.props.page],
            rightSlider: this.props.categories[this.props.category].pages[this.props.page].rightSlider,
            leftSlider: this.props.categories[this.props.category].pages[this.props.page].leftSlider,
            currentImgRight: 0,
            currentImgLeft: 0,
            height: props.height
        };

        this.timer = null;
    }

    componentDidMount() {
        this.setState({ height: window.innerHeight + "px" });
        this.interval = setInterval(() => this.changeBackgroundImage(), 3000);
    }

    componentWillReceiveProps(nextProps) {
        // update state when category changed
        if (this.props != nextProps) {
            this.props = nextProps;
            this.setState({
                categories: this.props.categories,
                category: this.props.category,
                currentCategory: this.props.categories[this.props.category],
                page: this.props.page,
                currentPage: this.props.categories[this.props.category].pages[this.props.page],
                rightSlider: this.props.categories[this.props.category].pages[this.props.page].rightSlider,
                leftSlider: this.props.categories[this.props.category].pages[this.props.page].leftSlider,
                currentImgRight: 0,
                currentImgLeft: 0
            });
        }
    }

    changeBackgroundImage() {
        const { rightSlider, leftSlider, currentImgRight, currentImgLeft, currentPage } = this.state;
        if (currentPage.slug !== "skystar_100111") {
            this.setState({
                currentImgRight: currentImgRight !== rightSlider.length - 1 ? currentImgRight + 1 : 0,
                currentImgLeft: currentImgLeft !== leftSlider.length - 1 ? currentImgLeft + 1 : 0
            });
        }
    }

    nextVideo = e => {
        let currentPageTemp = this.state.currentPage;
        currentPageTemp.video = "https://s3.us-east-1.amazonaws.com/rt-lta/skystar/110_video.mp4";
        this.setState({ currentPage: currentPageTemp }, () => {});
    };

    prevVideo = e => {
        let currentPageTemp = this.state.currentPage;
        currentPageTemp.video = "https://s3.us-east-1.amazonaws.com/rt-lta/skystar/100_video.mp4";
        this.setState({ currentPage: currentPageTemp }, () => {});
    };

    render() {
        const {
            categories,
            category,
            currentCategory,
            page,
            currentPage,
            rightSlider,
            leftSlider,
            currentImgRight,
            currentImgLeft,
            imgGallery,
            videoGallery
        } = this.state;

        const menu = Object.keys(categories).map(function(key) {
            let firstPage = Object.keys(categories[key].pages)[0];
            let title = categories[key].title;
            let titleCapitalized = title.charAt(0).toUpperCase() + title.slice(1);
            titleCapitalized = titleCapitalized === "Skystar" ? "SkyStar" : titleCapitalized;

            return (
                <li key={key} className={category == key ? "active" : null}>
                    <Link to={"/" + key + "/" + firstPage}>{titleCapitalized}</Link>
                </li>
            );
        });

        const pages = Object.keys(categories[category].pages).map(function(key) {
            //if(Object.keys(currentCategory.pages)[0] == key)
            //  return;
            return (
                <li key={key} className={page == key ? "active" : null}>
                    <Link to={"/" + category + "/" + key}>{categories[category].pages[key].title}</Link>
                </li>
            );
        });

        let backgroundImgRight = rightSlider[currentImgRight] ? rightSlider[currentImgRight]["image"] : false;
        let next, prev;

        if (
            currentPage.hasOwnProperty("video") &&
            currentPage.video == "https://s3.us-east-1.amazonaws.com/rt-lta/skystar/100_video.mp4" &&
            1 == 2
        ) {
            backgroundImgRight = "https://rt-lta.herokuapp.com/uploads/skystar_100_right_1.jpg";
            next = (
                <a className="video-nav next" onClick={e => this.nextVideo(e)}>
                    SKYSTAR 110
                </a>
            );
        }

        if (
            currentPage.hasOwnProperty("video") &&
            currentPage.video == "https://s3.us-east-1.amazonaws.com/rt-lta/skystar/110_video.mp4" &&
            1 == 2
        ) {
            backgroundImgRight = "https://rt-lta.herokuapp.com/uploads/skystar_100_right_2.jpg";
            prev = (
                <a className="video-nav prev" onClick={e => this.prevVideo(e)}>
                    SKYSTAR 100
                </a>
            );
        }

        return (
            <div id="home">
                <div id="fixed-header">
                    <div id="header">
                        <div id="logo">
                            <Link to="/">
                                <img src={"../imgs/logo.png"} alt="RT" />
                            </Link>
                        </div>
                        <ul>{menu}</ul>
                    </div>
                    <div id="pages">
                        <ul>{pages}</ul>
                    </div>
                </div>

                {category === "gallery" ? (
                    currentPage.slug == "images" ? (
                        <ImageGallery items={imgGallery} />
                    ) : (
                        <ImageGallery items={videoGallery} />
                    )
                ) : (
                    <div id="slideshow">
                        <div className="side right">
                            {backgroundImgRight && (
                                <div
                                    className="img"
                                    style={{
                                        backgroundImage: `url(${backgroundImgRight})`,
                                        backgroundPosition: `${rightSlider[currentImgRight]["background_position"]}`
                                    }}
                                >
                                    {currentPage.hasOwnProperty("video") && currentPage.video != null ? (
                                        <Video src={currentPage.video} />
                                    ) : null}
                                    {prev}
                                    {next}
                                </div>
                            )}
                        </div>

                        <div className="side left">
                            <div
                                className="img"
                                style={
                                    leftSlider[currentImgLeft]
                                        ? {
                                              backgroundImage: `url(${leftSlider[currentImgLeft]["image"]})`,
                                              backgroundPosition: `${leftSlider[currentImgLeft]["background_position"]}`
                                          }
                                        : null
                                }
                            >
                                <div className="flying-block">
                                    <div className="head">
                                        <h1>{currentPage.title}</h1>
                                    </div>
                                    <div className="body">
                                        <div
                                            className="inside"
                                            dangerouslySetInnerHTML={{ __html: currentPage.content }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

Home.defaultProps = {
    height: "800px"
};

export default App;
